<template>
    <div  class="footer">
        <div class="footer__content footer__container">
            <h2>Контакти</h2>
            <div class="footer__flex">
            <div class="footer__content__left">
                <div class="footer__content-block footer__content-block-padding">
                    <div class="footer__content-text">
                <img src="@/assets/img/footer_call.svg" alt="footer_call">
                  <a href="tel:+380669627170">380669627170 </a> -
                <span> Губанов Сергій Анатолійович</span>
            </div>
            <div class="footer__content-text">
                <span>Робочі години : з <b>10</b> до <b>15</b></span>
            </div>
            </div>
            <div class="footer__content-text footer_extra-padding">
                <img src="@/assets/img/footer_message.svg" alt="footer_message">
                <a href="mailto:asliktua@gmail.com">asliktua@gmail.com</a>
            </div>
            <div class="footer__content__left-bottom">
                <span><b>Наша адреса:</b></span>
                <span>41854, Україна, Білопільський р-н, Сумська обл., 
                    смт Миколаївка(з), Б. Свободи, будинок, 1а</span>
            </div>
            </div>
            <div class="footer__content__right">
                <img src="@/assets/img/footer_HS.svg" alt="footer_HS">
            </div>
            </div>
        </div>
    </div>
</template>

<script setup>

</script>

<style scoped lang="scss">
@import "@/assets/css/style.css";
</style>